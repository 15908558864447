<template>
  <div class="mx-2 report">
    <div class="container noPrint">
      <h1 class="my-3">Budget Reports</h1>
      <div class="row filter">
        <div class="col-12">
          <div>
            <div class="row">
              <div class="col-12 col-md-6 col-lg-4">
                <label class="col-12 font-weight-bold pt-2">Clients</label>
                <alert
                  v-if="usersWithRolesLoading"
                  class="py-8-px light-shadow"
                ></alert>
                <select
                  v-model="filter.clients"
                  @change="filter.filterIsDirty = true"
                  class="form-control light-shadow"
                  v-if="!usersWithRolesLoading"
                >
                  <option value="">All Clients</option>
                  <option
                    v-for="p in clientsList"
                    :key="p.user_id"
                    :value="p.user_id"
                  >
                    {{ p.name }}
                  </option>
                </select>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <label class="col-12 font-weight-bold pt-2">Providers</label>
                <alert
                  v-if="usersWithRolesLoading"
                  class="py-8-px light-shadow"
                ></alert>
                <select
                  v-model="filter.provider"
                  @change="filter.filterIsDirty = true"
                  class="form-control light-shadow"
                  v-if="!usersWithRolesLoading"
                >
                  <option value="">All Providers</option>
                  <option
                    v-for="p in providersList"
                    :key="p.user_id"
                    :value="p.user_id"
                  >
                    {{ p.name }}
                  </option>
                </select>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <label class="col-12 font-weight-bold pt-2">Period Type</label>

                <select
                  v-model="filter.period_type"
                  @change="filter.filterIsDirty = true"
                  class="form-control light-shadow"
                >
                  <option
                    v-for="p in periodOptions"
                    :key="p.value"
                    :value="p.value"
                  >
                    {{ p.label }}
                  </option>
                </select>
              </div>
            </div>
            <br />
            <br />
            <div class="row border-bottom align-items-center">
              <label class="col-12 col-md-1 font-weight-bold py-2 mb-2"
                >Date</label
              >
              <div class="mt-2 pt-1 col-12 col-md-6">
                <v-date-picker
                  v-model="filter.range"
                  @input="filter.filterIsDirty = true"
                  mode="date"
                  :masks="masks"
                  is-range
                >
                  <template v-slot="{ inputValue, inputEvents, isDragging }">
                    <div
                      class="
                        d-flex
                        justify-content-start
                        align-items-center
                        form-group
                      "
                    >
                      <input
                        class="px-2 py-1 form-control light-shadow"
                        :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                        :value="inputValue.start"
                        v-on="inputEvents.start"
                      />
                      <div class="mx-4">-</div>
                      <input
                        class="px-2 py-1 form-control light-shadow"
                        :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                        :value="inputValue.end"
                        v-on="inputEvents.end"
                      />
                    </div>
                  </template>
                </v-date-picker>
              </div>
            </div>
            <br />
            <div class="row border-bottom">
              <div class="col-11 d-flex flex-wrap mt-2 pt-1">
                <div class="custom-control custom-checkbox pb-4">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="combineServicesMaxHours"
                    v-model="filter.combineServicesMaxHours"
                    :disabled="loading"
                    @change="handleExcelData()"
                  />
                  <label
                    class="custom-control-label mr-3"
                    for="combineServicesMaxHours"
                  >
                    Combine services budgets
                    <div class="text-small text-muted">
                      Note: Money Budget data is displayed in combined services
                      mode
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <div class="my-4 d-flex justify-content-between" v-if="!loading">
              <div class="d-flex flex-wrap align-items-center">
                <button
                  class="
                    mr-3
                    btn btn-black
                    text-uppercase
                    view-report
                    position-relative
                    mt-3
                  "
                  :disabled="usersWithRolesLoading"
                  @click="getReportByFilter"
                >
                  View Report
                  <div
                    class="action-indicator btn btn-danger spinner-grow"
                    v-if="filter.filterIsDirty"
                  ></div>
                </button>
                <span v-if="shouldViewReport && maxHoursData.length">
                  <b> {{ maxHoursData.length || 0 }}</b>
                  Client{{ maxHoursData.length != 1 ? "s" : "" }}
                </span>
              </div>
              <div class="d-flex flex-wrap">
                <div class="d-flex mt-3 mr-3">
                  <button
                    class="ml-3 btn btn-theme text-uppercase"
                    @click="handlePrintButton"
                    :disabled="usersWithRolesLoading || !maxHoursData.length"
                  >
                    <span v-if="!logoLoading">
                      <i class="fas fa-print fa-lg"></i> Print</span
                    >
                    <i
                      class="fas fa-spin fa-circle-notch"
                      v-if="logoLoading"
                    ></i>
                  </button>
                  <div class="btn-group">
                    <button
                      type="button"
                      class="btn p-1"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      :disabled="usersWithRolesLoading || !maxHoursData.length"
                    >
                      <i class="fas fa-cog"></i>
                    </button>
                    <div
                      class="dropdown-menu print-menu"
                      @click="$event.stopPropagation()"
                    >
                      <h3 class="mx-3 my-2">
                        <i class="fas fa-cog mr-2"></i>Print settings
                      </h3>
                      <div class="dropdown-divider"></div>
                      <div class="dropdown-item">
                        <div class="custom-control custom-switch">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="show_second_logo_in_report"
                            v-model="printSettings.show_second_logo_in_report"
                            @change="logoReady = false"
                          />
                          <label
                            class="custom-control-label"
                            for="show_second_logo_in_report"
                            >Second Logo</label
                          >
                        </div>
                      </div>
                      <div class="dropdown-item">
                        <div class="custom-control custom-switch">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="show_name_in_report"
                            v-model="printSettings.show_client_name_in_report"
                          />
                          <label
                            class="custom-control-label"
                            for="show_name_in_report"
                            >Name</label
                          >
                        </div>
                      </div>
                      <div class="dropdown-item">
                        <div class="custom-control custom-switch">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="show_email_in_report"
                            v-model="printSettings.show_client_email_in_report"
                          />
                          <label
                            class="custom-control-label"
                            for="show_email_in_report"
                            >Email</label
                          >
                        </div>
                      </div>
                      <div class="dropdown-item">
                        <div class="custom-control custom-switch">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="show_phone_number_in_report"
                            v-model="
                              printSettings.show_client_phone_number_in_report
                            "
                          />
                          <label
                            class="custom-control-label"
                            for="show_phone_number_in_report"
                            >Phone Number</label
                          >
                        </div>
                      </div>
                      <div class="dropdown-item">
                        <div class="custom-control custom-switch">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="show_claim_number_in_report"
                            v-model="
                              printSettings.show_client_claim_number_in_report
                            "
                          />
                          <label
                            class="custom-control-label"
                            for="show_claim_number_in_report"
                            >Claim Number</label
                          >
                        </div>
                      </div>
                      <div class="dropdown-item">
                        <div class="custom-control custom-switch">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="show_date_of_loss_in_report"
                            v-model="
                              printSettings.show_client_date_of_loss_in_report
                            "
                          />
                          <label
                            class="custom-control-label"
                            for="show_date_of_loss_in_report"
                            >Date of Loss</label
                          >
                        </div>
                      </div>
                      <div class="dropdown-item">
                        <div class="custom-control custom-switch">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="show_date_of_birth_in_report"
                            v-model="
                              printSettings.show_client_date_of_birth_in_report
                            "
                          />
                          <label
                            class="custom-control-label"
                            for="show_date_of_birth_in_report"
                            >Date of Birth</label
                          >
                        </div>
                      </div>
                      <div class="dropdown-item">
                        <div class="custom-control custom-switch">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="show_insurer_in_report"
                            v-model="
                              printSettings.show_client_insurer_in_report
                            "
                          />
                          <label
                            class="custom-control-label"
                            for="show_insurer_in_report"
                            >Insurer</label
                          >
                        </div>
                      </div>
                      <div class="dropdown-item">
                        <div class="custom-control custom-switch">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="show_funding_source_in_report"
                            v-model="
                              printSettings.show_client_funding_source_in_report
                            "
                          />
                          <label
                            class="custom-control-label"
                            for="show_funding_source_in_report"
                            >Fund Source</label
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex mt-3 mr-3">
                  <JsonExcel
                    :data="excelData"
                    name="CRM-Budget-Report"
                    worksheet="Budget Report"
                    :fields="excel_fields"
                    default-value="No Data Found To Show"
                    header="Budget Report"
                  >
                    <button
                      class="ml-3 btn btn-deep-success text-uppercase"
                      :disabled="usersWithRolesLoading || !maxHoursData.length"
                    >
                      <i class="far fa-file-excel fa-lg"></i> Save Excel
                    </button>
                  </JsonExcel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <alert v-if="loading" />
    <div
      v-if="!loading && shouldViewReport"
      class="card overflow-auto noPrint light-shadow border-lg-0"
    >
      <div
        class="p-3 noPrint"
        v-if="maxHoursData.length == 0 && shouldViewReport"
      >
        No Data.
      </div>
      <div class="wrapper">
        <table
          v-if="maxHoursData.length > 0 && shouldViewReport"
          class="table table-sm table-striped reportTable noPrint mb-0"
        >
          <thead>
            <tr>
              <th rowspan="2" colspan="1" class="sticky-col first-col">
                Client
              </th>
              <th rowspan="2" colspan="1" class="sticky-col second-col">
                Service
              </th>
            </tr>
            <tr>
              <th class="scrollable-columns">
                Budget - {{ submittedFilter.period_type }}
              </th>
              <th
                v-for="(period, index) in periods"
                :key="index"
                class="scrollable-columns"
              >
                {{ period.toDisplay }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="client in maxHoursData" :key="client.id">
              <td class="sticky-col first-col">
                {{ client.name }}
              </td>
              <td class="sticky-col second-col">
                <div
                  v-for="(serviceData, index) in client.services"
                  :key="index"
                >
                  <div
                    v-if="
                      serviceData.parent_service_id == null &&
                      filter.combineServicesMaxHours
                    "
                  >
                    <div>&nbsp;</div>
                  </div>
                  <div class="ellipsis">
                    {{ serviceData.name }}
                  </div>
                  <div
                    v-if="
                      serviceData.parent_service_id == null &&
                      filter.combineServicesMaxHours
                    "
                  >
                    <div>&nbsp;</div>
                  </div>
                  <small
                    v-if="serviceData.parent_service_id"
                    class="text-muted ellipsis d-inline-block w-100"
                  >
                    (Sub Service of
                    {{
                      getParentService(
                        serviceData.parent_service_id,
                        client.services
                      )
                    }})</small
                  >
                  <hr v-if="index + 1 !== client.services.length" />
                </div>
              </td>
              <td class="scrollable-columns">
                <tr
                  v-for="(serviceMaxHours, index) in client.services"
                  :key="index"
                >
                  <template v-if="filter.combineServicesMaxHours">
                    <div v-if="serviceMaxHours.parent_service_id == null">
                      <div class="inner-value">
                        <span>
                          {{
                            parseFloat(
                              getMaxHoursSummationFor(
                                serviceMaxHours,
                                client.services
                              )
                            ).toFixed(2)
                          }}H
                        </span>
                        <span>
                          {{
                            serviceMaxHours.budget
                              ? `$${parseFloat(serviceMaxHours.budget)
                                  .toFixed(2)
                                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`
                              : "N/A"
                          }}
                        </span>
                      </div>
                      <hr v-if="index + 1 !== client.services.length" />
                    </div>
                    <div v-else>
                      <div>&nbsp;</div>
                      <hr
                        v-if="index + 1 !== client.services.length"
                        style="visibility: hidden"
                      />
                    </div>
                  </template>
                  <template v-else>
                    <div>
                      {{
                        serviceMaxHours.max_hours_value != null
                          ? parseFloat(serviceMaxHours.max_hours_value)
                              .toFixed(2)
                              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                          : "-"
                      }}
                    </div>
                    <hr v-if="index + 1 !== client.services.length" />
                  </template>
                </tr>
              </td>
              <td
                v-for="(period, periodIndex) in periods"
                :key="periodIndex"
                class="scrollable-columns"
              >
                <template v-if="filter.combineServicesMaxHours">
                  <div
                    v-for="(service, index) in client.services"
                    :key="service.id"
                  >
                    <tr v-if="service.parent_service_id == null">
                      <div class="inner-value">
                        <span>
                          {{
                            parseFloat(
                              getHoursSummationFor(
                                periodIndex,
                                service,
                                client.services
                              )
                            ).toFixed(2)
                          }}H
                          <span
                            :class="
                              getHoursSummationFor(
                                periodIndex,
                                service,
                                client.services
                              ) >
                              getMaxHoursSummationFor(service, client.services)
                                ? 'exceedMaxHours'
                                : 'notExceedMaxHours'
                            "
                          >
                            ({{
                              parseFloat(
                                Math.abs(
                                  parseFloat(
                                    getMaxHoursSummationFor(
                                      service,
                                      client.services
                                    )
                                  ) -
                                    parseFloat(
                                      getHoursSummationFor(
                                        periodIndex,
                                        service,
                                        client.services
                                      )
                                    )
                                ).toFixed(2)
                              ).toFixed(2)
                            }}H)</span
                          >
                        </span>
                        <span>
                          ${{
                            parseFloat(
                              getHoursSummationFor(
                                periodIndex,
                                service,
                                client.services
                              ) * service.service_rate
                            )
                              .toFixed(2)
                              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                          }}
                          <span
                            v-if="service.budget != null"
                            :class="
                              getHoursSummationFor(
                                periodIndex,
                                service,
                                client.services
                              ) *
                                service.service_rate >
                              service.budget
                                ? 'exceedMaxHours'
                                : 'notExceedMaxHours'
                            "
                          >
                            (${{
                              parseFloat(
                                Math.abs(
                                  parseFloat(service.budget || 0) -
                                    parseFloat(
                                      getHoursSummationFor(
                                        periodIndex,
                                        service,
                                        client.services
                                      ) * service.service_rate
                                    )
                                ).toFixed(2)
                              )
                                .toFixed(2)
                                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                            }})
                          </span>
                          <span v-else class="text-info">(N/A)</span>
                        </span>
                      </div>
                      <hr v-if="index + 1 !== client.services.length" />
                    </tr>
                    <tr v-else>
                      <div>&nbsp;</div>
                      <hr
                        v-if="index + 1 !== client.services.length"
                        style="visibility: hidden"
                      />
                    </tr>
                  </div>
                </template>
                <div
                  v-else
                  v-for="(service, serviceIndex) in client.services"
                  :key="serviceIndex"
                >
                  <tr>
                    {{
                      parseFloat(
                        service.appointmentHoursPerPeriod[periodIndex]
                      ).toFixed(2)
                    }}H
                    <span
                      :class="
                        service.appointmentHoursPerPeriod[periodIndex] >
                        service.max_hours_value
                          ? 'exceedMaxHours'
                          : 'notExceedMaxHours'
                      "
                    >
                      ({{
                        parseFloat(
                          Math.abs(
                            parseFloat(service.max_hours_value) -
                              parseFloat(
                                service.appointmentHoursPerPeriod[periodIndex]
                              )
                          ).toFixed(2)
                        )
                      }}H)</span
                    >
                    <hr v-if="serviceIndex + 1 !== client.services.length" />
                  </tr>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="container printableReport" v-if="maxHoursData.length > 0">
      <div class="reportData row firstPage printPage">
        <div class="col-md-8 reportCompanyLogo">
          <img
            :src="
              printSettings.show_second_logo_in_report &&
              companyData.second_logo
                ? companyData.second_logo
                : companyData.logo
            "
            @load="logoLoaded"
          />
        </div>
        <div class="row col-md-12 m-0 reportHeader">
          <div class="col-md-12" v-if="companyData.show_name_in_report">
            {{ companyData.name || "-" }}
          </div>
          <div class="col-md-12" v-if="companyData.show_bio_in_report">
            {{ companyData.bio }}
          </div>
        </div>
        <div class="row PatientData">
          <div class="col-md-12">
            <p v-if="companyData.show_email_in_report && companyData.email">
              <label>Email: </label>{{ companyData.email || "-" }}
            </p>

            <p v-if="companyData.show_location_in_report">
              <label>Location: </label>{{ companyData.location || "-" }}
            </p>
            <p v-if="companyData.show_lawyer_in_report">
              <label>Lawyer: </label>{{ companyData.lawyer || "-" }}
            </p>
            <p v-if="companyData.show_phone_number_in_report">
              <label>Phone Number: </label>{{ companyData.phone_number || "-" }}
            </p>
            <p>
              <label>Report Dates Range: </label
              >{{
                this.prettyDate(this.filter.range.start, "MMM D, YYYY ") +
                " - " +
                this.prettyDate(this.filter.range.end, "MMM D, YYYY ")
              }}
            </p>
          </div>
        </div>
      </div>
      <!-- report Pages -->
      <div
        class="reportData row printPage"
        v-for="clientReport in this.maxHoursData"
        :key="clientReport.id"
      >
        <div class="row">
          <div
            class="pageTopReportInfoHeader col-12"
            style="
              font-weight: bold;
              border-bottom: 1px solid;
              margin-bottom: 10px;
            "
          >
            <div
              class="col-6"
              style="float: left"
              v-if="companyData.show_name_in_report"
            >
              {{ companyData.name }} Report
            </div>

            <div class="col-6" style="float: right; text-align: right">
              Report Dates Range:
              {{
                prettyDate(filter.range.start, "MMM D, YYYY ") +
                " - " +
                prettyDate(filter.range.end, "MMM D, YYYY ")
              }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="col-md-5" style="text-align: center; margin: 0px auto">
              <img
                :src="
                  printSettings.show_second_logo_in_report &&
                  companyData.second_logo
                    ? companyData.second_logo
                    : companyData.logo
                "
                style="height: 100px"
                class="reportCompanyLogo"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="col-4" v-if="printSettings.show_client_name_in_report">
              <label style="font-weight: bold; text-align: right">Name:</label>

              {{ clientReport.name || "-" }}
            </div>
            <div class="col-4" v-if="printSettings.show_client_email_in_report">
              <label style="font-weight: bold; text-align: right">Email:</label>

              {{ clientReport.email || "-" }}
            </div>
            <div
              class="col-4"
              v-if="printSettings.show_client_claim_number_in_report"
            >
              <label style="font-weight: bold; text-align: right"
                >Claim Number:</label
              >
              {{ clientReport.claim_number || "-" }}
            </div>
            <div
              class="col-4"
              v-if="printSettings.show_client_insurer_in_report"
            >
              <label style="font-weight: bold; text-align: right"
                >Insurer:</label
              >

              {{ clientReport.insurer || "-" }}
            </div>
            <div
              class="col-4"
              v-if="printSettings.show_client_date_of_loss_in_report"
            >
              <label style="font-weight: bold">Date Of Loss:</label>
              {{ prettyDate(clientReport.date_of_loss) || "-" }}
            </div>
            <div
              class="col-4"
              v-if="printSettings.show_client_date_of_birth_in_report"
            >
              <label style="font-weight: bold">Date Of Birth:</label>
              {{
                prettyDate(clientReport.date_of_birth, "MMM DD, YYYY") || "-"
              }}
            </div>
            <div
              class="col-4"
              v-if="printSettings.show_client_funding_source_in_report"
            >
              <label style="font-weight: bold">Funding Source:</label>
              {{ getFundingSourceLabel(clientReport.funding_source) || "-" }}
            </div>
          </div>
        </div>
        <hr />
        <div>
          <div class="row" v-if="!filter.combineServicesMaxHours">
            <div
              v-for="service in clientReport.services"
              :key="service.id"
              class="col-5 border rounded p-3 ml-3 mb-3 d-flex flex-column gap"
            >
              <div>
                <label style="font-weight: bold; text-align: left" class="m-0"
                  >Service:</label
                >
                {{ service.name || "-" }}
                <small v-if="service.parent_service_id" style="color: #333">
                  (Sub Service of
                  {{
                    getParentService(
                      service.parent_service_id,
                      clientReport.services
                    )
                  }})</small
                >
              </div>
              <div>
                <label style="font-weight: bold; text-align: left"
                  >Service Budget in hours:</label
                >

                {{ service.max_hours_value || "-" }}
              </div>
              <div class="d-flex flex-column gap">
                <label style="font-weight: bold; text-align: left" class="m-0"
                  >Budget value per
                  {{ periodMapping[submittedFilter.period_type] }}:</label
                >
                <div>
                  <div v-for="(period, index) in periods" :key="index">
                    <label style="font-weight: bold; text-align: left"
                      >{{ period.toDisplay }}:
                    </label>

                    {{
                      parseFloat(
                        service.appointmentHoursPerPeriod[index]
                      ).toFixed(2)
                    }}H
                    <span
                      :class="
                        service.appointmentHoursPerPeriod[index] >
                        service.max_hours_value
                          ? 'exceedMaxHours'
                          : 'notExceedMaxHours'
                      "
                    >
                      ({{
                        parseFloat(
                          Math.abs(
                            parseFloat(service.max_hours_value) -
                              parseFloat(
                                service.appointmentHoursPerPeriod[index]
                              )
                          ).toFixed(2)
                        )
                      }}H)</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-if="filter.combineServicesMaxHours">
            <div
              v-for="service in getServices(clientReport.services, null)"
              :key="service.id"
              class="col-12 p-3 ml-3 mb-3 d-flex flex-column gap"
            >
              <div>
                <label style="font-weight: bold; text-align: left" class="m-0">
                  Main Service:
                </label>
                {{ service.name || "-" }}
              </div>
              <div>
                <label style="font-weight: bold; text-align: left" class="m-0">
                  Sub services:
                </label>
                {{
                  getServices(clientReport.services, service.id)
                    .map((service) => service.name)
                    .join(", ") || "-"
                }}
              </div>
              <div>
                <label style="font-weight: bold; text-align: left">
                  Budget in hours:
                </label>
                {{
                  getMaxHoursSummationFor(service, clientReport.services) || "-"
                }}
              </div>
              <div>
                <label style="font-weight: bold; text-align: left">
                  Budget in $:
                </label>
                {{ service.budget || "N/A" }}
              </div>
              <div class="d-flex flex-column gap">
                <label style="font-weight: bold; text-align: left" class="m-0">
                  Budget value per
                  {{ periodMapping[submittedFilter.period_type] }}:
                </label>
                <div class="d-flex flex-wrap gap">
                  <div
                    v-for="(period, index) in periods"
                    :key="index"
                    class="col-4 border rounded p-3 mb-3"
                  >
                    <label
                      style="font-weight: bold; text-align: left"
                      class="mr-2"
                    >
                      {{ period.toDisplay }}:
                    </label>
                    <div class="d-flex flex-column items-align-center">
                      <span>
                        <label style="font-weight: bold; text-align: left">
                          In Hours:
                        </label>
                        {{
                          parseFloat(
                            getHoursSummationFor(
                              index,
                              service,
                              clientReport.services
                            )
                          ).toFixed(2)
                        }}H
                        <span
                          :class="
                            getHoursSummationFor(
                              index,
                              service,
                              clientReport.services
                            ) >
                            getMaxHoursSummationFor(
                              service,
                              clientReport.services
                            )
                              ? 'exceedMaxHours'
                              : 'notExceedMaxHours'
                          "
                        >
                          ({{
                            parseFloat(
                              Math.abs(
                                parseFloat(
                                  getMaxHoursSummationFor(
                                    service,
                                    clientReport.services
                                  )
                                ) -
                                  parseFloat(
                                    getHoursSummationFor(
                                      index,
                                      service,
                                      clientReport.services
                                    )
                                  )
                              ).toFixed(2)
                            ).toFixed(2)
                          }}H)</span
                        >
                      </span>
                      <span>
                        <label style="font-weight: bold; text-align: left">
                          In $:
                        </label>
                        ${{
                          parseFloat(
                            getHoursSummationFor(
                              index,
                              service,
                              clientReport.services
                            ) * service.service_rate
                          )
                            .toFixed(2)
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                        }}
                        <span
                          v-if="service.budget != null"
                          :class="
                            getHoursSummationFor(
                              index,
                              service,
                              clientReport.services
                            ) *
                              service.service_rate >
                            service.budget
                              ? 'exceedMaxHours'
                              : 'notExceedMaxHours'
                          "
                        >
                          (${{
                            parseFloat(
                              Math.abs(
                                parseFloat(service.budget || 0) -
                                  parseFloat(
                                    getHoursSummationFor(
                                      index,
                                      service,
                                      clientReport.services
                                    ) * service.service_rate
                                  )
                              )
                            )
                              .toFixed(2)
                              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                          }})
                        </span>
                        <span v-else class="text-info">(N/A)</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
        </div>
      </div>
      <!--End Page -->
    </div>
  </div>
</template>
<script>
import dateFormat from "@/utils/dateFormat";
import { mapActions, mapState } from "vuex";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import JsonExcel from "vue-json-excel";

dayjs.extend(utc);
dayjs.extend(timezone);

export default {
  name: "maxhours-report",
  components: {
    JsonExcel,
  },
  data() {
    return {
      excelData: [],
      excel_fields: {},
      shouldViewReport: false,
      periodMapping: {
        Daily: "day",
        Weekly: "week",
        Monthly: "month",
      },
      periodOptions: [
        { value: "Daily", label: "Daily" },
        { value: "Weekly", label: "Weekly" },
        { value: "Monthly", label: "Monthly" },
      ],
      fundingSourceOptions: [
        { value: "government", label: "Government" },
        { value: "insurance_company", label: "Insurance company" },
        { value: "other", label: "Other" },
      ],
      masks: {
        input: "YYYY-MM-DD",
      },
      printSettings: {
        show_client_name_in_report: true,
        show_client_email_in_report: true,
        show_client_phone_number_in_report: true,
        show_client_claim_number_in_report: true,
        show_client_date_of_birth_in_report: true,
        show_client_date_of_loss_in_report: true,
        show_client_funding_source_in_report: true,
        show_client_insurer_in_report: true,
      },
      submittedFilter: {
        period_type: "Monthly",
      },
      logoReady: false,
      logoLoading: false,
      filter: {
        filterIsDirty: true,
        clients: "",
        provider: "",
        period_type: "Monthly",
        range: {
          start: new Date(),
          end: new Date(),
        },
        combineServicesMaxHours: false,
      },
    };
  },

  computed: {
    ...mapState({
      companyData: (state) => state.settings.companyProfile,
      maxHoursData: (state) => state.reports.maxHoursData,
      periods: (state) => state.reports.maxHoursPeriods,
      loading: (state) => state.reports.isLoading,
      users: (state) => state.security.usersWithRoles,
      usersWithRolesLoading: (state) => state.security.usersWithRolesLoading,
    }),
    clientsList: function () {
      if (this.users && this.users.length) {
        return this.users.filter((user) => user.roleId === 3);
      } else {
        return [];
      }
    },
    providersList: function () {
      if (this.users && this.users.length) {
        return this.users.filter((user) => user.roleId === 2);
      } else {
        return [];
      }
    },
    getAllAppointmentHoursFor: function () {
      return (periodIndex, services) => {
        return services.reduce((total, service) => {
          return total + service.appointmentHoursPerPeriod[periodIndex];
        }, 0);
      };
    },
    getMaxHoursFor: function () {
      return (services) => {
        return services.reduce(
          (total, item) => total + item.max_hours_value,
          0
        );
      };
    },
    getMaxHoursSummationFor: function () {
      return (mainService, services) => {
        return services.reduce((total, item) => {
          if (
            item.id == mainService.id ||
            item.parent_service_id == mainService.id
          ) {
            return total + item.max_hours_value;
          }
          return total;
        }, 0);
      };
    },
    getHoursSummationFor: function () {
      return (periodIndex, mainService, services) => {
        return services.reduce((total, service) => {
          if (
            service.id == mainService.id ||
            service.parent_service_id == mainService.id
          ) {
            return total + service.appointmentHoursPerPeriod[periodIndex];
          }
          return total;
        }, 0);
      };
    },
    getServices: function () {
      return (services, criteria) => {
        return services.filter(
          (service) => service.parent_service_id == criteria
        );
      };
    },
  },
  created() {
    this.getCompanyProfile();
  },
  methods: {
    ...mapActions({
      getReports: "reports/getMaxhours",
      getCompanyProfile: "settings/getCompanyProfile",
    }),
    getReportByFilter() {
      if (!this.filter.filterIsDirty) {
        return;
      }
      const payload = {
        period_type: this.filter.period_type,
      };
      const startDateTime = `${dateFormat(
        this.filter.range.start,
        "YYYY-MM-DD"
      )}T00:00:00.000000Z`;
      const endDateTime = `${dateFormat(
        this.filter.range.end,
        "YYYY-MM-DD"
      )}T23:59:59.000000Z`;
      const canadaOffsetStart = dayjs
        .tz(new Date(startDateTime), "America/Toronto")
        .utcOffset();
      const canadaOffsetEnd = dayjs
        .tz(new Date(endDateTime), "America/Toronto")
        .utcOffset();
      payload.beginDateIntervals = dayjs(startDateTime)
        .add(canadaOffsetStart, "minute")
        .utc()
        .format();
      payload.endDateIntervals = dayjs(endDateTime)
        .add(canadaOffsetEnd, "minute")
        .utc()
        .format();
      this.submittedFilter.period_type = this.filter.period_type;
      if (this.filter.clients) {
        payload.client_id = this.filter.clients;
      }
      if (this.filter.provider) {
        payload.provider_id = this.filter.provider;
      }
      const vm = this;
      this.getReports(payload).then(() => {
        vm.excelData = [];
        vm.filter.filterIsDirty = false;
        vm.preparePeriodsForDisplay();
        vm.shouldViewReport = true;
        vm.handleExcelData();
      });
    },
    handleExcelData: function () {
      if (!this.shouldViewReport || !this.maxHoursData.length) {
        return;
      }
      this.excel_fields = {};
      this.excelData = [];
      this.prepareExcelFields();
      const vm = this;
      vm.maxHoursData.forEach(function (clientData) {
        clientData.services.forEach(function (service) {
          if (vm.filter.combineServicesMaxHours) {
            const maxHoursSummation = vm.getMaxHoursSummationFor(
              service,
              clientData.services
            );
            if (service.parent_service_id == null) {
              const moneyBudget = service.budget
                ? `$${parseFloat(service.budget)
                    .toFixed(2)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`
                : "N/A";
              let excelRow = {
                id: clientData.id,
                name: clientData.name,
                service: service.name,
                maxHoursValue: `${maxHoursSummation}H\n${moneyBudget}`,
              };
              vm.periods.forEach(function (period, index) {
                const hoursSummation = vm.getHoursSummationFor(
                  index,
                  service,
                  clientData.services
                );
                const cellData = `${parseFloat(hoursSummation).toFixed(
                  2
                )}H\n$${parseFloat(hoursSummation * service.service_rate)
                  .toFixed(2)
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`;
                excelRow = {
                  ...excelRow,
                  [`${period.toDisplay}`]: cellData,
                };
              });
              vm.excelData.push(excelRow);
            }
          } else {
            let excelRow = {
              id: clientData.id,
              name: clientData.name,
              service: service.name,
              maxHoursValue: service.max_hours_value,
            };
            vm.periods.forEach(function (period, index) {
              excelRow = {
                ...excelRow,
                [`${period.toDisplay}`]: parseFloat(
                  service.appointmentHoursPerPeriod[index]
                ).toFixed(2),
              };
            });
            vm.excelData.push(excelRow);
          }
        });
        if (vm.filter.combineServicesMaxHours) {
          vm.insertSeparator();
        } else {
          vm.insertTotalRow(clientData);
        }
      });
    },
    prepareExcelFields: function () {
      this.excel_fields = {
        "Client Name": {
          field: "name",
        },
        ID: {
          field: "id",
        },
        "Service Name": {
          field: "service",
        },
        [`Budget - ${this.submittedFilter.period_type}`]: {
          field: "maxHoursValue",
        },
      };
      const vm = this;
      this.periods.forEach((period) => {
        vm.excel_fields = {
          ...vm.excel_fields,
          [`${period.toDisplay}`]: {
            field: period.toDisplay,
            callback: (value) => {
              return `${value}`;
            },
          },
        };
      });
    },
    insertSeparator: function () {
      let separatorRow = {
        id: "-----------",
        name: "-----------",
        service: "-----------",
        maxHoursValue: "-----------",
      };
      this.periods.forEach(function (period) {
        separatorRow = {
          ...separatorRow,
          [`${period.toDisplay}`]: "-----------",
        };
      });
      this.excelData.push(separatorRow);
    },
    insertTotalRow: function (clientData) {
      let totalRow = {
        id: "<table><tr style='background-color: #FFE699; font-weight:bold'><td>-----------</td></tr></table>`",
        name: `<table><tr style='background-color: #FFE699; font-weight:bold'><td>Total For ${clientData.name}</td></tr></table>`,
        service:
          "<table><tr style='background-color: #FFE699; font-weight:bold'><td>-----------</td></tr></table>`",
        maxHoursValue: `<table><tr style='background-color: #FFE699; font-weight:bold'><td>${this.getMaxHoursFor(
          clientData.services
        )}</td></tr></table>`,
      };
      const vm = this;
      this.periods.forEach(function (period, index) {
        totalRow = {
          ...totalRow,
          [`${period.toDisplay}`]: `<table><tr style='background-color: #FFE699; font-weight:bold'><td>${vm.getAllAppointmentHoursFor(
            index,
            clientData.services
          )}</td></tr></table>`,
        };
      });
      this.excelData.push(totalRow);
      this.insertSeparator();
    },
    preparePeriodsForDisplay: function () {
      for (var index = 0; index < this.periods.length; index++) {
        if (this.submittedFilter.period_type == "Monthly") {
          this.periods[index].toDisplay = dayjs(this.periods[index].end)
            .utc()
            .format("MMM/YYYY");
        } else if (this.submittedFilter.period_type == "Weekly") {
          this.periods[index].toDisplay = `(${dayjs(this.periods[index].start)
            .utc()
            .format("DD/MMM")} - ${dayjs(this.periods[index].end)
            .utc()
            .format("DD/MMM")}) / ${dayjs(this.periods[index].end)
            .utc()
            .format("YY")}`;
        } else if (this.submittedFilter.period_type == "Daily") {
          this.periods[index].toDisplay = dayjs(this.periods[index].end)
            .utc()
            .format("DD/MMM/YY");
        }
      }
    },
    prettyDate: function (date, format = null) {
      if (date == undefined || date == null) {
        return;
      }
      if (!dayjs(date).isValid()) {
        return "-";
      }
      if (!format) {
        return dateFormat(date, "human");
      } else {
        return dateFormat(date, format);
      }
    },
    logoLoaded: function () {
      this.logoReady = true;
    },
    handlePrintButton: function () {
      if (!this.shouldViewReport || !this.maxHoursData.length) {
        return;
      }
      if (
        this.logoReady ||
        !this.companyData.logo ||
        (this.printSettings.show_second_logo_in_report &&
          !this.companyProfile.second_logo)
      ) {
        this.logoReady = true; // incase company logo does not exist
        window.print();
        return;
      }
      this.logoLoading = true;
      const interval = setInterval(() => {
        if (this.logoReady) {
          clearInterval(interval);
          this.logoLoading = false;
          window.print();
        }
      }, 500);
    },
    getParentService: function (serviceId, services) {
      const filteredServices = services.filter((service) => {
        return service.id == serviceId;
      });
      return filteredServices.length ? filteredServices[0].name : "-";
    },
    getFundingSourceLabel: function (value) {
      var foundValue = this.fundingSourceOptions.find(
        (item) => item.value == value
      );
      if (foundValue != null) {
        return foundValue.label;
      } else {
        return "-";
      }
    },
  },
};
</script>

<style scoped>
@media print {
  @page {
    size: auto;
  }
  body {
    background-color: #fff !important;
  }
  .printPage {
    page-break-after: always;
    margin-top: 120px;
  } /* printPage-break-after works, as well */
}
.reportCompanyLogo img {
  height: 200px;
}
.reportCompanyLogo {
  margin: 50px auto;
  text-align: center;
}
</style>
<style lang="scss" scoped>
.inner-value {
  display: flex;
  flex-direction: column;
  > span:first-child {
    border-bottom: 1px dashed #062157;
  }
  > span {
    padding: 3px 5px;
  }
}
.gap {
  gap: 0.5rem;
}
.print-menu {
  max-height: 350px;
  overflow-y: auto;
  z-index: 1000;
  .dropdown-item.disabled {
    opacity: 0.6;
    &,
    input,
    label {
      pointer-events: none;
      &::before,
      &::after {
        pointer-events: none;
      }
    }
  }
}
.wrapper {
  position: relative;
  overflow: auto;
  white-space: nowrap;
  max-height: 680px;
}
table {
  th {
    position: sticky;
    top: 0;
  }
  tr td {
    background-color: #f9fbfd;
  }
  tr:nth-child(even) td {
    background-color: #ffff;
    &:after {
      background-color: #ffff;
    }
  }
  .scrollable-columns {
    text-align: center;
    tr {
      display: block;
      text-align: center;
    }
  }
  tbody {
    .second-col::after {
      z-index: 1;
      position: absolute;
      content: "";
      top: 0px;
      right: 0px;
      height: 100%;
      width: 10px;
      background: #f9fbfd;
      box-shadow: 8px 0 5px 0px rgb(191 205 222 / 80%);
    }
  }
  .sticky-col {
    position: sticky;
    z-index: 1;
  }

  th.sticky-col {
    z-index: 2;
  }

  .first-col {
    width: 150px;
    min-width: 150px;
    max-width: 150px;
    left: 0;
  }

  .second-col {
    width: 400px;
    min-width: 400px;
    max-width: 400px;
    left: 150px;
  }
}

.py-8-px {
  padding-top: 8px;
  padding-bottom: 8px;
}
.action-indicator {
  width: 12px;
  height: 12px;
  padding: 0;
  position: absolute;
  top: 0px;
  right: 1px;
}
tr tr {
  background-color: transparent !important;
}
tr:nth-of-type(odd) tr {
  background-color: #f9fbfd !important;
}

.border-bottom {
  border-bottom-color: var(--secondary-color) !important;
}
</style>
